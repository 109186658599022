@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

#root {
	height: 100%;
}

body {
	margin: 0;
	font-family: "Poppins", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
}

.background1 {
	/* min-height: 100%; */
	/* height: 100vh !important; */
	height: 100vh;
	width: 100vw;
	background-image: url("images/backgroundwithlogo.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.footer1 {
	/* padding: 10px 10px 0px 10px;
	bottom: 0;
	width: 100%; */
	/* Height of the footer*/
	/* min-height: 50px;
	background: transparent; */
}

.background2 {
	/* min-height: 100%; */
	/* height: 100vh !important; */
	height: 100vh;
	width: 100vw;
	background-image: url("images/2.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.itemCenter {
	/* display: grid;
	grid-auto-flow: column;
	gap: 4px;
	align-items: center;
	justify-items: center; */
	color: "white";
}
